
<div class="spin" *ngIf="!loading">
  <div class="spinner-border" role="status" >
  <span class="sr-only"> Loading... </span>
</div>
</div>
<div  *ngIf="fawryData?.referenceNumber!=='undefined' && loading">
  <p class="text-success text-center pt-3 p_success">{{'Process Successes' | translate}}</p>
<div class="col-lg-12  offset-lg-0 ar-text-right" >
  <div class="main-border">
    <p class="summary col-gray">{{ "Fawry" | translate }} :</p>
    <hr />
    <div class="container">
      <div class="row py-2 imp">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Reference Number" | translate }}
        </div>
        <div class="col-6 text-black mid-font pr-5">
          {{ fawryData?.referenceNumber | translate }}
        </div>
      </div>
      <div class="row py-2">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Customer Name" | translate }}
        </div>
        <div class="col-6 text-black mid-font pr-5 bold">
          {{ fawryData?.customerName | translate }}
        </div>
      </div>

      <div class="row py-2">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Payment Amount" | translate }}
        </div>
        <div class="col-6 text-black mid-font pr-5 bold">
          {{ fawryData?.paymentAmount | translate }}
          <span class="bold">{{"EGP"}}</span>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Fawry Fees" | translate }}
        </div>
        <div class="col-6 text-black mid-font pr-5 bold">
          <span *ngIf="fawryData?.fawryfees">{{  fawryData?.fawryfees }}</span>
          <span *ngIf="!fawryData?.fawryfees">{{  '0 ' }}</span>
          <span class="bold">{{"EGP"}}</span>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Order Status" | translate }}
        </div>
        <div class="col-6 text-black mid-font pr-5 bold">
          {{ fawryData?.orderStatus | translate }}
        </div>
      </div>
      <div class="row py-2">
        <div class="col-6 text-black mid-font pl-4 bold">
          {{ "Expiry Date" | translate }}
        </div>
        <div *ngIf="fawryData?.expirationTime" class="col-6 text-black mid-font pr-5 bold">
          {{ fawryData?.expirationTime| date: 'dd/MM/yyyy h:mm:ss a' }}
        </div>
        <div *ngIf="!fawryData?.expirationTime" class="col-6 text-black mid-font pr-5 bold">
          {{ "-" }}
        </div>
      </div>
    </div>
  </div>

</div>
</div>
<div *ngIf="fawryData?.referenceNumber === 'undefined'">
  <div class="main-border mt-5">
    <p class=" text-center pt-3 p_success">{{'Reference Number is already send' | translate}} !</p>
  </div>

</div>
