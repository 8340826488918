<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <img width="30px" src="assets/icons/menuIcon.png" alt="">
    </a>
    <img class="logo-header" src="assets/images/Logo english - property payment0.png" alt="">
  </div>

</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->

    <nb-action class="control-item messages position-relative" [routerLink]="['./contact-us']">
      <img width="20px" src="assets/icons/messages.png" alt="">
      <span *ngIf="messages>0" class="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle">
      </span>
    </nb-action>
    <!-- <nb-action class="control-item">
      <img width="20px" src="assets/icons/notification.png" alt="">
    </nb-action> -->
    <nb-action *ngIf="userRole == '3'" class="control-item d-inline">
      <p  class="sms">{{"SMS Count" | translate}} {{': '}}
        <span [ngClass]="smsBalance>100?'smsValue':'text-danger'" class="text-center">{{smsBalance}}</span>
      </p>

    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" [class.noUserStyle]="isLoadingUserData">

      <div class="spinner-border" role="status" *ngIf="isLoadingUserData">
        <span class="sr-only"> Loading... </span>
      </div>

      <nb-user *ngIf="!(isLoadingUserData)" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly"
        [name]="userData?.full_name" [picture]="userData?.logo">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
