<div class="d-flex justify-content-center">
  <!-- <div *ngIf="data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="logo" [src]="data?.receiver?.logo" alt="" />
  </div>
  <div *ngIf="!data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="w-25" src="assets/images/Logo english - property payment0.png">
  </div> -->
  <!-- <div class=" tax col-lg-6 mb-md-3 head-text d-flex justify-content-center align-items-center">
    <p>{{ "Tax Registration Number "  | translate }}</p>
    <p> &nbsp; {{'('+ '262-034-573'+ ')'}}</p>
  </div> -->
</div>
<div [ngClass]="data_receipts?'container':'container pt-5'">
  <div class="row">
    <div class="bg-white" [ngClass]="data_receipts?'width-100-center':'width-80-center'">
      <div class="col-md-10 offset-md-1 receipt">
        <div>
          <div class="d-flex justify-content-between pt-5 payment-details">
            <div>
              <p class="f-700">{{ "Payment Receipt" | translate }}</p>
            </div>
            <P class="text-center process ">
              <span class="d-block py-2">{{ "Your Process No. " | translate }}
                <span style="color: #cc9900"> {{ "#" }}{{ data?.receipts?.id || id}}</span></span>
              {{ "Succesfully Completed on" | translate }}
              <span>{{ date }}</span> {{"at" |translate}} {{data?.date_of_payment | date: 'HH:mm:ss'}}
            </P>
            <div>
              <p class="padge">{{ "Successful Process" | translate }}</p>
            </div>
          </div>
          <hr>
          <div class="container">

            <div [dir]="lang=='en'?'ltr':'rtl'" class="dir py-3 d-flex justify-content-between ">
              <div>
                <p class="f-700">{{ "Service Type" | translate }}</p>
                <p class="text-muted" *ngIf="data?.invoices[0].name != 'Bee Invoice'">
                  {{ "Rent" | translate }}
                </p>
                <p *ngIf="data?.invoices[0].name == 'Bee Invoice'">
                  {{ "Utilities" | translate }}
                </p>
              </div>
              <div>
                <p *ngIf="data?.invoices[0].name == 'Bee Invoice'" class="f-700">{{ "Subscriper" | translate }}</p>
                <p *ngIf="data?.invoices[0].name !== 'Bee Invoice'" class="f-700">{{ "Payer Name" | translate }}</p>
                <p class="text-muted">{{ data?.payer?.name || data?.invoices[0]?.payer?.name || '-'}}</p>
              </div>
              <div>
                <p *ngIf="data?.invoices[0].name == 'Bee Invoice'" class="f-700">{{ "Subscriper Phone" | translate }}
                </p>
                <p *ngIf="data?.invoices[0].name !== 'Bee Invoice'" class="f-700">{{ "Payer Phone" | translate }}</p>
                <p class="text-muted">{{ data?.payer?.tel1 || data?.invoices[0]?.payer?.tel1 || '-'}}</p>
              </div>
            </div>
            <!-- <div *ngIf="data?.contract?.unit" class="d-flex justify-content-between pt-3">
              <div>
                <p class="f-700">{{ "Unit Name" | translate }}</p>
                <p>{{ data?.contract?.unit?.name  || '-'}}</p>
              </div>
              <div>
                <p class="f-700">{{ "Unit Number" | translate }}</p>
                <p>{{ data?.contract?.unit?.unit_no || '-' }}</p>
              </div>
            </div> -->
          </div>
          <div class="text-center">
            <p class="f-700 f-s-20">{{ "Payment Details" | translate }}</p>
          </div>
          <!-- invoices -->
          <div *ngFor="let invoice of data?.invoices">
            <div *ngIf="invoice.name !== 'Bee Invoice' ">

              <div [dir]="lang=='en'?'ltr':'rtl'" class="invoice-container">
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Invoice Number' | translate}}</p>
                  <p>{{invoice.id || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Invoice Name' | translate}}</p>
                  <p>{{invoice.name || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Contract Name' | translate}}</p>
                  <p>{{invoice.contract?.contract_name || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Building Name' | translate}}</p>
                  <p>{{invoice.contract?.unit?.building?.name || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Unit Number' | translate}}</p>
                  <p>{{invoice.contract?.unit?.unit_no || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br">
                  <p class="">{{'Region' | translate}}</p>
                  <p>{{invoice.contract?.unit?.building?.region || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between br brl">
                  <p class="">{{'Payment Method' | translate}}</p>
                  <p>{{data.payment_method?.name || '-'}}</p>
                </div>
                <div class="d-flex justify-content-between total">
                  <p class="f-w-600">{{'Amount' | translate}}</p>
                  <p class="f-w-600">{{formate.formatNum(invoice?.amount_paid) || '0.00'}}</p>
                </div>
              </div>
              </div>
              <div *ngIf="invoice.name == 'Bee Invoice' ">
                <div [dir]="lang=='en'?'ltr':'rtl'" class="invoice-container">
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Invoice Number' | translate}}</p>
                    <p>{{invoice.id || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Invoice Name' | translate}}</p>
                    <p>{{invoice.name || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Provider Name' | translate}}</p>
                    <p *ngIf="lang=='en'">{{invoice.utility_log?.service_provider_name || '-'}}</p>
                    <p *ngIf="lang=='ar'">{{invoice.utility_log?.service_provider_name_ar || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Service Type' | translate}}</p>
                    <p>{{invoice.utility_log?.service_type || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Transaction Number' | translate}}</p>
                    <p>{{invoice.utility_log?.transaction_number || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br">
                    <p class="">{{'Transaction Code' | translate}}</p>
                    <p>{{invoice.transaction_id || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between br brl">
                    <p class="">{{'Payment Method' | translate}}</p>
                    <p>{{data.payment_method?.name || '-'}}</p>
                  </div>
                  <div class="d-flex justify-content-between total">
                    <p class="f-w-600">{{'Amount' | translate}}</p>
                    <p class="f-w-600">{{formate.formatNum(invoice?.utility_log?.transaction_amount) || '0.00'}}</p>
                  </div>
                </div>
              </div>
            <hr>
          </div>
          <div [dir]="lang=='en'?'ltr':'rtl'">
            <div class="d-flex justify-content-between pt-1">
              <p class="f-700 text-center">{{ "Description" | translate }}</p>
              <p class="f-700 text-center">{{ "Amount" | translate }}</p>

            </div>
            <div class="d-flex justify-content-between">
              <div>
                {{ "Service Amount" | translate }}
              </div>
              <div>
                <p *ngIf="data?.name != 'Bee Invoice'">
                  {{
                  formate.formatNum(data?.total_amount)|| '0.00'
                  }}
                  {{ "EGP" | translate }}
                </p>
                <p *ngIf="data?.name === 'Bee Invoice'">
                  {{formate.formatNum( data?.amount_total ) || '0.00'}} {{ "EGP" | translate }}
                </p>
              </div>
            </div>
            <div *ngIf="data?.name != 'Bee Invoice'" class="d-flex justify-content-between">
              <div>
                <div>
                  {{ "Service Fees" | translate }}
                </div>
              </div>
              <div>
                <p>
                  {{ formate.formatNum(calcTotal(data?.utility_fees , data?.round_up ,data?.fixed_fees)) || '0.00'}}
                  {{ "EGP" | translate }}
                </p>
              </div>
            </div>
          </div>
          <hr>
          <div class="d-flex justify-content-between total">
            <div>
              <p class="f-700">{{"Total Amount" | translate}} </p>
            </div>
            <div *ngIf="data?.name != 'Bee Invoice'">
              <p class="f-700"> {{ formate.formatNum(calcTotal(data?.total_amount ,data?.service_fees )) || '0.00'}} {{
                "EGP" | translate }}</p>
            </div>
            <div *ngIf="data?.invoices[0]?.name === 'Bee Invoice'">
              <p class="f-700"> {{ formate.formatNum(data?.checkout_amount)|| '0.00'}} {{
                "EGP" | translate }}</p>
            </div>
          </div>
        </div>
        <div class="py-3 d-flex justify-content-center">
          <div (click)="copyLink()" class="print pointer mx-2">
            <img class="w-4" src="../../../assets/images/share-svgrepo-com.svg">
            <span>{{"SHARE" | translate}}</span>
          </div>
          <div (click)="print()" class="print mx-2 d-flex align-items-center ">
            <img class="w-4" src="../../../assets/images/printer-svgrepo-com.svg"><span>{{"PRINT" | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
